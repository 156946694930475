const GetRequest = () => {
  const url = location.search //获取url中"?"符后的字串
  // const theRequest = new Object();
  const theRequest: any = {}
  if (url.indexOf('?') != -1) {
    const str = url.substr(1)
    const strs = str.split('&')
    for (let i = 0; i < strs.length; i++) {
      theRequest[strs[i].split('=')[0]] = decodeURIComponent(strs[i].split('=')[1])
    }
  }
  return theRequest
}

//判断字体需要
const GetUrlHostType = () => {
  let urlHost = window.location.host
  // let urlReg = /[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+.?/
  // let url: any = urlReg.exec(urlHost)
  let urlType = urlHost.split('.')[0]
  if (urlType == 'hk' || urlType == 'tw') {
    return 'complexFont'
  } else {
    return 'simplifiedFont'
  }
}
//判断埋点需要
const GetClstagType = () => {
  let urlHost = window.location.host
  let urlType = urlHost.split('.')[0]
  if (urlType == 'hk') {
    return 'hk'
  } else if (urlType == 'tw') {
    return 'taiwan'
  } else {
    return 'global'
  }
}

const toFixed = (num, digit) => {
  try {
    num = parseFloat(num)
  } catch (e) {
    num = 0
  }
  return num.toFixed(digit)
}

const onlyDecimal = (num, digit) => {
  const str = toFixed(num, digit)
  return str.split('.')[1]
}

const onlyInteger = (num) => {
  const str = toFixed(num, 2)
  return str.split('.')[0]
}

export default { GetRequest, GetUrlHostType, GetClstagType, onlyDecimal, onlyInteger }
