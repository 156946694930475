import React, { useState, useEffect, Fragment } from 'react'
import './index.scss'
import mainPlanService from '@app/api/mainPlanService'
import { isTemplateExpression } from 'typescript'
import utils from '@app/utils/index'
import { useLocation, useHistory } from 'react-router-dom'
export interface IAppProps {}

const members = () => {
  // const {  } = props;
  const fontType = utils.GetUrlHostType()
  const clstagType = utils.GetClstagType()
  const [judgeLogin, setJudgeLogin] = useState('')
  const [userInfo, setUserInfo] = useState<any>({}) //个人用户信息
  const [enterpriseUser, setEnterpriseUser] = useState<any>({}) //企业用户信息
  const [medalsType, setMedalsType] = useState<any>({}) //奖牌数据
  const [isNewUser, setIsNewUser] = useState<any>({}) //是否是新人
  const [userHref, seTuserHref] = useState<any>([]) //会员面板图标列表
  const [news, seNews] = useState<any>([]) //特讯

  const login = function () {
    return (
      (location.href =
        'https://passport.jd.com/new/login.aspx?ReturnUrl=' + escape(location.href).replace(/\//g, '%2F')),
      !1
    )
  }
  const regist = function () {
    return (location.href = 'https://reg.jd.com/p/regPage?source=IBG&ReturnUrl=' + escape(location.href)), !1
  }
  const logout = function () {
    return (
      (location.href =
        'https://passport.jd.com/uc/login?ltype=logout&ReturnUrl=' + escape(location.href).replace(/\//g, '%2F')),
      !1
    )
  }
  const getUserInfo = async () => {
    //判断用户是否登录
    var isLoginUser: any = await mainPlanService.isLogin()
    setJudgeLogin(isLoginUser.Identity.IsAuthenticated)
    //如果用户已经登录，获取用户信息
    if (isLoginUser.Identity.IsAuthenticated) {
      var userInfoServer: any = await mainPlanService.userInfoData()
      if (userInfoServer.userLevel == 7) {
        console.log('企业用户')
      } else {
        setUserInfo(userInfoServer)
        var medalsServer: any = await mainPlanService.getMedelsData()
        setMedalsType(medalsServer.data)
        mainPlanService.getIsNewUserData().then((res: any) => {
          setIsNewUser(res.isNew)
        })
      }
    }

    mainPlanService.getRequestData(window['serverIdList'].userIcon).then((res) => {
      seTuserHref(res.advertInfos.list)
    })
    mainPlanService.getRequestData(window['serverIdList'].messageListId).then((res) => {
      let list = res.advertInfos.list
      // seNews(list.length > 3 ? list.filter((item, index) => index < 2) : list)
      seNews(list)
    })
  }

  const medalsFn = () => {
    switch (medalsType.level) {
      case 'Regist':
        return (
          <a href="//vip.jd.com/" target="_blank" title="普通会员">
            <i className="user_lvico_1"></i>
          </a>
        )
      case 'Copper':
        return (
          <a href="//vip.jd.com/" target="_blank" title="铜牌会员">
            <i className="user_lvico_2"></i>
          </a>
        )
      case 'Silver':
        return (
          <a href="//vip.jd.com/" target="_blank" title="银牌会员">
            <i className="user_lvico_3"></i>
          </a>
        )
      case 'Gold':
        return (
          <a href="//vip.jd.com/" target="_blank" title="金牌会员">
            <i className="user_lvico_4"></i>
          </a>
        )
      case 'Diamonds':
        return (
          <a href="//vip.jd.com/" target="_blank" title="钻石会员">
            <i className="user_lvico_5"></i>
          </a>
        )
    }
  }
  const newUserTypeFn = () => {
    switch (userInfo.plusStatus) {
      case '0':
      case '1':
      case '2':
      case '4':
        return (
          <a className="renewal" href="//plus.jd.com" clstag="pageclick|keycount|taiwan|login_02">
            开通PLUS 平均省1012元/年
          </a>
        )
      case '3':
        return (
          <a className="renewal" href="//plus.jd.com" clstag="pageclick|keycount|taiwan|login_02">
            现在续费享特惠价
          </a>
        )
      case '5':
        return (
          <a className="renewal" href="//plus.jd.com" clstag="pageclick|keycount|taiwan|login_02">
            只差一步~激活PLUS享特权
          </a>
        )
    }
  }
  const messsageBtLink = () => {
    switch (clstagType) {
      case 'hk':
        return '//hk.jd.com/kuaibao.html'
      case 'taiwan':
        return '//phat.jd.com/10-218.html'
      case 'global':
        return '//phat.jd.com/10-198.html'
    }
  }

  useEffect(() => {
    //初始化信息
    getUserInfo()
  }, [])

  // useEffect(() => {
  //   getUserInfo()
  // }, [pathname])

  return (
    <div className="members_cen">
      {judgeLogin ? (
        userInfo ? (
          <>
            <div className="user_cen">
              <div className="head">
                <a href="">
                  <img src={userInfo?.imgUrl}></img>
                </a>
              </div>
              <div className="user_info">
                <div className="welcome">
                  HI～
                  <a href="//home.jd.com" target="_blank">
                    {userInfo?.nickName}
                  </a>
                </div>
                <div className="user_vip">
                  <a href="//vip.jd.com/" target="_blank" title={userInfo?.userScoreVO?.totalScore}>
                    <i className="jxz"></i>
                  </a>
                  {medalsFn()}
                  <a className="logout" href="//vip.jd.com/" target="_blank" onClick={logout}>
                    退出
                  </a>
                </div>
              </div>
            </div>
            {!isNewUser ? <div className="user_btn">{newUserTypeFn()}</div> : ''}
          </>
        ) : null
      ) : (
        <>
          <div className="user_cen">
            <div className="head">
              <a href="" target="_blank">
                <img src="https://img10.360buyimg.com/imagetools/jfs/t1/39610/10/21462/25175/63a907d9Eb4694b9a/f02971fe7708657f.png"></img>
              </a>
            </div>
            <div className="user_info">
              <div className="welcome">HI～{fontType == 'complexFont' ? '歡迎來到京東' : '欢迎来到京东'}!</div>
              <span className="logo">
                <a
                  onClick={() => {
                    login()
                  }}
                >
                  {fontType == 'complexFont' ? '登錄' : '登录'}
                </a>
                <span className="vertical_line">|</span>
                <a
                  onClick={() => {
                    regist()
                  }}
                >
                  {fontType == 'complexFont' ? '註冊' : '注册'}
                </a>
              </span>
            </div>
          </div>
          <div className="user_btn">
            <a
              className="newUser"
              clstag={`pageclick|keycount|${fontType == 'complexFont' ? 'taiwan' : 'global'}|login_02`}
              target="_blank"
              href="//xinren.jd.com/?channel=99"
            >
              新人福利
            </a>
            <a
              className="plus"
              clstag={`pageclick|keycount|${fontType == 'complexFont' ? 'taiwan' : 'global'}|login_03`}
              target="_blank"
              href="//plus.jd.com"
            >
              PLUS会员
            </a>
          </div>
        </>
      )}

      <div className="line"></div>
      <div className="information">
        <div className="information_bt clr">
          <span>{clstagType == 'hk' ? '港澳特訊' : clstagType == 'global' ? '全球特讯' : '台灣特訊'}</span>
          <a
            target="_blank"
            href={messsageBtLink()}
            clstag={`pageclick|keycount|${fontType == 'complexFont' ? 'taiwan' : 'global'}|notice_01`}
          >
            更多<i></i>
          </a>
        </div>
        <div className="information_list">
          <ul>
            {news &&
              news.map((item, index) => {
                return (
                  <li key={item.advertId}>
                    <a
                      href={item.link}
                      target="_blank"
                      clstag={`pageclick|keycount|${fontType == 'complexFont' ? 'taiwan' : 'global'}|news_0${
                        index + 1
                      }`}
                    >
                      {item.name}
                    </a>
                  </li>
                )
              })}
          </ul>
        </div>
      </div>
      <div className="line"></div>
      <div className="after_sales">
        <ul className="clr">
          {userHref &&
            userHref.map((item, index) => {
              return (
                <li key={item.advertId}>
                  <a href={item.link} target="_blank">
                    <img src={item.pictureUrl}></img>
                    <span>{item.name}</span>
                  </a>
                </li>
              )
            })}
        </ul>
      </div>
      <div className="pay_list">
        <ul className="clr">
          <li className="icon-item">
            <img
              alt="visa"
              src="//img13.360buyimg.com/imagetools/jfs/t1/11399/29/19442/1311/63aa9f93F3c146bfa/545433ffb72a255b.png"
            />
          </li>
          <li className="icon-item">
            <img
              alt="master"
              src="//img13.360buyimg.com/imagetools/jfs/t1/135205/12/28597/894/63aa9fb8F0bd85706/f9331d572339c2c9.png"
            />
          </li>
          <li className="icon-item">
            <img
              alt="JCB"
              src="//img12.360buyimg.com/imagetools/jfs/t1/120455/21/32606/1186/63aa9fb8Fc7d267b1/0c0aed67c7ed0011.png"
            />
          </li>
          <li className="icon-item">
            <img
              alt="銀聯"
              src="//img14.360buyimg.com/imagetools/jfs/t1/174674/5/27511/1212/63aa9f63F2349dcff/46cbe614556fc49b.png"
            />
          </li>
          <li className="icon-item">
            <img
              alt="微信"
              src="//img11.360buyimg.com/imagetools/jfs/t1/137860/10/33220/973/63aa9fb8Ff0d2242e/1bc40bfb4fa91dd2.png"
            />
          </li>
          <li className="icon-item">
            <img
              alt="八達通"
              src="//img11.360buyimg.com/imagetools/jfs/t1/111825/12/32276/1564/63aa9fb8F755397c3/216d20251b5723bd.png"
            />
          </li>
        </ul>
      </div>
    </div>
  )
}
export default members
