import React, { useState, useEffect, Fragment } from 'react'
import './index.scss'
import mainPlanService from '@app/api/mainPlanService'
import { isTemplateExpression } from 'typescript'
import utils from '@app/utils/index'
export interface IAppProps {}

const Panking = () => {
  // const {  } = props;
  const fontType = utils.GetUrlHostType()
  const [recoList, setRecoList] = useState<any>('')

  const getPromoteData = async () => {
    mainPlanService.getRequestGoodsData(window['serverIdList'].recommendId).then((res) => {
      setRecoList(res.gift_card.list)
    })
  }

  useEffect(() => {
    //初始化信息
    getPromoteData()
  }, [])

  return (
    <div className="recommend_cen">
      <h5 className="p_bt">
        <span className="bt_dian_l"></span>
        {fontType == 'complexFont' ? '為你推薦' : '为你推荐'}
        <span className="bt_dian_r"></span>
      </h5>
      <div className="clr">
        <div className="recommend_list">
          <div className="center clr">
            {recoList &&
              recoList.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="item"
                    clstag={`pageclick|keycount|goods_1574230803301|guess_${index}`}
                    onClick={() => {
                      window.open(`https://item.jd.com/${item.skuId}.html`)
                    }}
                  >
                    <img src={item.image}></img>
                    <div className="item_title">{item.name}</div>
                    <div className="price">
                      <span className="symbol">￥</span>
                      {`${utils.onlyInteger(item.jdPrice)}.`}
                      <span className="price_remaining">{utils.onlyDecimal(item.jdPrice, 2)}</span>
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </div>
  )
}
export default Panking
