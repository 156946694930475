import React, { useState, useEffect, Fragment } from 'react'
import './index.scss'
import mainPlanService from '@app/api/mainPlanService'
import { isTemplateExpression } from 'typescript'
import utils from '@app/utils/index'
export interface IAppProps {}

const Panking = () => {
  // const {  } = props;
  const fontType = utils.GetUrlHostType()
  const [rankingList, setRankingList] = useState<any>([])

  const getPromoteData = async () => {
    mainPlanService.getRequestData(window['serverIdList'].rankingId).then((res) => {
      setRankingList(res.advertInfos.list)
    })
  }

  useEffect(() => {
    //初始化信息
    getPromoteData()
  }, [])

  return (
    <div className="panking_cen">
      <h5 className="p_bt">
        <span className="bt_dian_l"></span>
        {fontType == 'complexFont' ? '"京"挑細選' : '"京"挑细选'}
        <span className="bt_dian_r"></span>
      </h5>
      <div className="clr">
        <div className="p_left" clstag="pageclick|keycount|ad_base_1600324744478|ad_0">
          {rankingList.length > 0 && (
            <a target="_blank" href={rankingList[0].link}>
              <img src={rankingList[0].pictureUrl}></img>
            </a>
          )}
        </div>
        <div className="p_right">
          <ul className="clr">
            {rankingList.length > 0 &&
              rankingList
                .filter((item, index) => index > 0)
                .map((item, index) => {
                  return (
                    <li key={index} clstag={`pageclick|keycount|goods_rank_1600324382958|list_0_${index}`}>
                      <a target="_blank" href={item.link}>
                        <img src={item.pictureUrl}></img>
                      </a>
                    </li>
                  )
                })}
          </ul>
        </div>
      </div>
    </div>
  )
}
export default Panking
