import React, { useState, useEffect, Fragment } from 'react'
import './index.scss'
import mainPlanService from '@app/api/mainPlanService'
import { isTemplateExpression } from 'typescript'
import utils from '@app/utils/index'
export interface IAppProps {}

const Channel = () => {
  const fontType = utils.GetUrlHostType()
  const [leftList, setLeftList] = useState([])
  const [rightList, setrightList] = useState([])
  const getPromoteData = async () => {
    mainPlanService.getRequestData(window['serverIdList'].squareLeftId).then((res) => {
      setLeftList(res.advertInfos.list)
    })
    mainPlanService.getRequestData(window['serverIdList'].squareRightId).then((res) => {
      setrightList(res.advertInfos.list)
    })
  }

  useEffect(() => {
    //初始化信息
    getPromoteData()
  }, [])

  return (
    <div className="channel_cen">
      <h5 className="c_bt">
        <span className="bt_dian_l"></span>
        {fontType == 'complexFont' ? '頻道廣場' : '频道广场'}
        <span className="bt_dian_r"></span>
      </h5>
      <div className="clr">
        <div className="c_left">
          {leftList.length > 0 &&
            leftList.map((item: any, index) => {
              return (
                <a
                  key={index}
                  href={item.link}
                  target="_blank"
                  clstag={`pageclick|keycount|ad_base_1600954919929|ad_${index}`}
                >
                  <img src={item.pictureUrl}></img>
                </a>
              )
            })}
        </div>
        <div className="c_right">
          <ul className="clr">
            {rightList.length > 0 &&
              rightList.map((item: any, index) => {
                return (
                  <li key={index} clstag={`pageclick|keycount|ad_base_1600954919929|ad_${index + 2}`}>
                    <a key={index} href={item.link} target="_blank">
                      <img src={item.pictureUrl}></img>
                    </a>
                  </li>
                )
              })}
          </ul>
        </div>
      </div>
    </div>
  )
}
export default Channel
