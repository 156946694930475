import ReactDOM from 'react-dom'
// import { Provider } from 'mobx-react'
// import store from './store/index'
import Router from './router/router'
import Home from '@app/pages/App'
import './assets/com.scss'

// import "core-js/stable";
// import "regenerator-runtime/runtime";
// import 'core-js/es'
// import 'core-js/es/map'
// import 'core-js/es/set'
// import 'react-app-polyfill/ie9'
// import 'react-app-polyfill/ie11'
// import 'react-app-polyfill/stable'

declare module 'react' {
  interface HTMLAttributes<T> {
    clstag?: string
  }
  interface RefAttributes<T> {
    clstag?: string
  }
  interface Window {
    log?: any
    expLogJSON?: any
  }
}

ReactDOM.render(
  // <Provider {...store}>
  <Home></Home>,
  // </Provider>,
  document.getElementById('root'),
)
