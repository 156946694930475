import React, { useState, useEffect, Fragment } from 'react'
import styled from './index.module.scss'
import mainPlanService from '@app/api/mainPlanService'
import utils from '@app/utils/index'
export interface IAppProps {}

const Menu = () => {
  // const {  } = props;
  const fontType = utils.GetUrlHostType()
  const clstagType = utils.GetClstagType()
  const [menuIdx, setMenuIdx] = useState(-1)
  const [menuData, setMenuData] = useState([])
  const [secondaryData, setSecondaryData] = useState([])
  const [oneItem, setOneItem] = useState([])
  const [itemExtInfo, setItemExtInfo] = useState({})
  const [idxCollection, setIdxCollection] = useState({})

  const getTwoData = (params, item, num) => {
    setItemExtInfo(item.ext_info)
    let isShow =
      secondaryData &&
      secondaryData.filter((item) => {
        return item.idx == num
      })
    if (isShow.length >= 1) {
      let one = secondaryData.filter((item) => item.idx == num)[0]
      let ary = []
      // console.log(one, "one");
      ary[0] = one.data[item.ext_info['layer_top_add']].data
      ary[1] = one.data[item.ext_info['layer_main_cate']].data
      ary[2] = one.data[item.ext_info['layer_right_top_ad']].data
      ary[3] = one.data[item.ext_info['layer_right_buttom_ad']].data
      setOneItem([...ary])
    } else {
      mainPlanService.getAggPoolData(params).then((res) => {
        // console.log(res, "res");
        let newData = secondaryData
        let ary = []
        ary[0] = res.data[item.ext_info['layer_top_add']].data
        ary[1] = res.data[item.ext_info['layer_main_cate']].data
        ary[2] = res.data[item.ext_info['layer_right_top_ad']].data
        ary[3] = res.data[item.ext_info['layer_right_buttom_ad']].data
        setSecondaryData([...newData, { idx: num, data: res.data }])
        setOneItem([...ary])
      })
    }
  }

  useEffect(() => {
    mainPlanService.getPoolData(window['serverIdList'].categoryId).then((res) => {
      // console.log(res, "res");
      setMenuData(res.data)
    })
    // getPoolData().then((res) => {
    //   console.log(res, 'res');
    // });
    // const fetchData = async () => {

    //   getCategory().then((res: any) => {
    //     setIsLongCate(res.value.isLongCate);
    //     setMenuData(res.value.category);
    //   });
    // };
    // fetchData();
  }, [])

  // useEffect(() => {
  //   console.log(secondaryData, "secondaryData");
  // }, [secondaryData]);

  // useEffect(() => {
  //   console.log(itemExtInfo, "itemExtInfo");
  // }, [itemExtInfo]);

  useEffect(() => {
    // console.log(oneItem, 'oneItem')
    // console.log(oneItem[0]["data"], "oneItem[0]");
    // if (oneItem[0] && oneItem.length > 0) {
    //   // console.log(oneItem[0]["data"], "oneItem[0]");
    //   // console.log(itemExtInfo, "itemExtInfo");
    //   console.log(oneItem[0]["data"][itemExtInfo["layer_right_buttom_ad"]], "oneItem");
    // }
  }, [oneItem])

  return (
    <div className={`${styled.conent}`}>
      <div className={`${styled.menu_conent}`}>
        <div
          className={`${styled.industry}`}
          onMouseLeave={() => {
            setMenuIdx(-1)
          }}
        >
          <div className={`${styled.menu_list}`}>
            {menuData.length > 0 &&
              menuData.map((item, index) => {
                return (
                  <div
                    key={item.id}
                    className={`${styled.menu_item} ${styled.text_space} ${index == menuIdx ? styled.menu_hover : ''}`}
                    onMouseEnter={() => {
                      setMenuIdx(index)
                      getTwoData(
                        [
                          { pid: item.ext_info.layer_top_add },
                          { pid: item.ext_info.layer_main_cate },
                          { pid: item.ext_info.layer_right_top_ad },
                          { pid: item.ext_info.layer_right_buttom_ad },
                        ],
                        item,
                        index,
                      )
                      // getRecBrand(item.cateId);
                    }}
                  >
                    {item.children &&
                      item.children.map((children, chiIdx) => {
                        return (
                          <Fragment key={children.id}>
                            <span className={`${styled.cate_menu_line} ${styled['fore' + chiIdx]}`}>/</span>
                            <a
                              href={children.link_value}
                              clstag={`pageclick|keycount|${clstagType}|item_header_title2_${chiIdx}`}
                            >
                              {children.title}
                            </a>
                          </Fragment>
                        )
                      })}
                  </div>
                )
              })}
          </div>
          <div className={`${styled.sub_menu} ${menuIdx >= 0 ? styled.active : ''}`}>
            {oneItem.length > 0 && (
              <>
                <div className={`${styled.left}`}>
                  <div className={`${styled.cate_channel}`}>
                    {oneItem[0].map((item1, index1) => {
                      return (
                        <a
                          key={item1.id}
                          className={`${styled.cate_channel_link}`}
                          href={item1.link_value}
                          clstag={`pageclick|keycount|${clstagType}|category_0${menuIdx + 1}b0${index1 + 1}`}
                        >
                          {item1.title}
                          <i className="iconfont"></i>
                        </a>
                      )
                    })}
                  </div>
                  <div>
                    {oneItem[1].map((item, index) => {
                      return (
                        <div key={item.id} className={`${styled.cate_sub_list} ${styled.clr}`}>
                          <a
                            href={item.link_value}
                            className={`${styled.cate_sub_title}`}
                            clstag={`pageclick|keycount|${clstagType}|category_0${menuIdx + 1}c0${index + 1}`}
                          >
                            {item.title}
                            <i className="iconfont"></i>
                          </a>
                          {item.children.map((item_chil, chiIdx) => {
                            return (
                              <a
                                key={item_chil.id}
                                href={item_chil.link_value}
                                className={`${styled.cate_sub_link}`}
                                clstag={`pageclick|keycount|${clstagType}|category_0${menuIdx + 1}d0${index + 1}0${
                                  chiIdx + 1
                                }`}
                              >
                                {item_chil.title}
                              </a>
                            )
                          })}
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className={`${styled.right}`}>
                  <div className={`${styled.cate_right_top}`}>
                    {oneItem[2].map((item, index) => {
                      return (
                        <a
                          key={item.id}
                          className={`${styled.cate_sub_link}`}
                          href={item.url}
                          clstag={`pageclick|keycount|${clstagType}|category_0${menuIdx + 1}e0${index + 1}`}
                        >
                          <img
                            alt={item.title}
                            width={item.width}
                            height={item.height}
                            src={'//img10.360buyimg.com/img/' + item.img_url + '!q90.webp'}
                          ></img>
                        </a>
                      )
                    })}
                  </div>
                  <div className={`${styled.cate_right_bottom}`}>
                    {oneItem[3].map((item, index) => {
                      return (
                        <a
                          key={item.id}
                          className={`${styled.cate_right_logo}`}
                          href={item.url}
                          clstag={`pageclick|keycount|${clstagType}|category_0${menuIdx + 1}f0${index + 1}`}
                        >
                          <img
                            alt={item.title}
                            width={item.width}
                            height={item.height}
                            src={'//img10.360buyimg.com/img/' + item.img_url + '!q90.webp'}
                          ></img>
                        </a>
                      )
                    })}
                  </div>
                </div>
              </>
            )}
            {/* <div>{JSON.stringify(oneItem[1])}</div> */}
            {/* <div>{JSON.stringify(oneItem[2])}</div>
            <div>{JSON.stringify(oneItem[3])}</div> */}
          </div>
        </div>
      </div>
    </div>
  )
}
export default Menu
