import React, { Component, useEffect, useState } from 'react'
import './index.scss'
import Contain from './compoments/index'
import mainPlanService from '@app/api/mainPlanService'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const Banner = () => {
  const [twoData, seTwoData] = useState([])
  const [banner, setBanner] = useState([])

  useEffect(() => {
    mainPlanService.getRequestData(window['serverIdList'].bannerId).then((res) => {
      setBanner(res.advertInfos.list)
    })
    mainPlanService.getRequestData(window['serverIdList'].bannerBottomId).then((res) => {
      seTwoData(res.advertInfos.list)
    })
  }, [])

  function NextArrowRender(props) {
    const { className, style, onClick } = props
    return (
      <div className="next_btn" onClick={onClick} clstag="pageclick|keycount|ad_swiper_1574230803498|next">
        <span></span>
      </div>
    )
  }
  function PrevArrowRender(props) {
    const { className, style, onClick } = props
    return (
      <div className="prev_btn" onClick={onClick} clstag="pageclick|keycount|ad_swiper_1574230803498|prev">
        <span></span>
      </div>
    )
  }
  const settings = {
    dots: true, //是否显示小圆点索引
    autoplay: true, //是否自动播放
    infinite: true, //是否无限循环
    autoplaySpeed: 3000, //自动播放的时间
    fade: false, //是否采用淡入淡出的效果
    slidesToShow: 1,
    slidesToScroll: 1,
    dotsClass: 'slick-dots1', //自定义指示器的样式
    speed: 500,
    height: 370,
    nextArrow: <NextArrowRender></NextArrowRender>,
    prevArrow: <PrevArrowRender></PrevArrowRender>,
  }

  return (
    <div>
      {/* <Contain></Contain> */}
      <div className="slider_main">
        <Slider {...settings} className="swiper-container">
          {banner.map((b: any) => {
            return (
              <div
                key={b.advertId}
                className="slider_center"
                clstag="pageclick|keycount|ad_swiper_1574230803498|item_0"
              >
                <a href={b.link} target="_blank">
                  <img src={b.pictureUrl} />
                </a>
              </div>
            )
          })}
        </Slider>
      </div>
      <div className="bottom_base_img clr">
        {twoData &&
          twoData.map((item: any, index) => {
            return (
              <div key={item.advertId}>
                <a href={item.link} target="_blank" clstag={`pageclick|keycount|imgarea_1600862719926|link_${index}`}>
                  <img src={item.pictureUrl}></img>
                </a>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default Banner
