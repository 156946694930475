import React, { useState, useEffect, Fragment } from 'react'
import './index.scss'
import mainPlanService from '@app/api/mainPlanService'
import { isTemplateExpression } from 'typescript'
export interface IAppProps {}

const Promote = () => {
  // const {  } = props;
  const [banner, setBanner] = useState([])

  const getPromoteData = async () => {
    mainPlanService.getRequestData(window['serverIdList'].promoteId).then((res) => {
      setBanner(res.advertInfos.list)
    })
  }

  useEffect(() => {
    //初始化信息
    getPromoteData()
  }, [])

  return (
    <div>
      {window['serverIdList'].promoteId ? (
        <div className="promote_cen">
          {banner &&
            banner.map((item: any, index) => {
              return (
                <div key={index}>
                  <a href={item.link} target="_blank" clstag="pageclick|keycount|imgarea_1600862719926|link_0">
                    <img src={item.pictureUrl} />
                  </a>
                </div>
              )
            })}
        </div>
      ) : null}
    </div>
  )
}
export default Promote
