import React, { useState, useEffect, Fragment } from 'react'
import './index.scss'
import mainPlanService from '@app/api/mainPlanService'
import { isTemplateExpression } from 'typescript'
export interface IAppProps {}

const ActivityTop = () => {
  // const {  } = props;
  const [isShowTopBanner, setIsShowTopBanner] = useState(true)
  const [topBanner, setTopBanner] = useState<any>([])

  const getPromoteData = async () => {
    mainPlanService.getTopBannerData(window['serverIdList'].topReachedId).then((res: any) => {
      if (res.code == 0) {
        setTopBanner(res.data)
      }
    })
  }

  const closeBtn = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setIsShowTopBanner(false)
  }

  useEffect(() => {
    //初始化信息
    getPromoteData()
  }, [])

  return (
    <div>
      {window['serverIdList'].topReachedId && topBanner && topBanner.length > 0 && isShowTopBanner ? (
        <div className="topReached" style={{ background: topBanner[0].operate_word }}>
          <a href={topBanner[0].url} target="_blank">
            <img src={'//img14.360buyimg.com/da/' + topBanner[0].img_url}></img>
            <span
              className="closeBtn"
              onClick={(e) => {
                closeBtn(e)
              }}
            ></span>
          </a>
        </div>
      ) : null}
    </div>
  )
}
export default ActivityTop
